import { css } from '@emotion/react';

export const global = css`
    @import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 16px;
        font-family: Rubik, Helvetica, Arial, sans-serif;
        color: #2d2d2d;
    }

    body {
        overflow-x: hidden;
    }

    .grecaptcha-badge {
        display: none !important;
    }

    .gatsby-image-wrapper {
        display: block;
    }
`;
