import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { differenceInDays } from 'date-fns';
import localForage from 'localforage';
import { AnimatePresence, motion } from 'framer-motion';

import Button from '@components/Button';
import Logo from '@components/Logo';

import * as s from './styles';

type AgeVerificationData = {
    timestamp: number;
    over21: boolean;
};

const AgeVerification = () => {
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        localForage.getItem<AgeVerificationData>('ageVerification').then((verification) => {
            if (verification) {
                if (differenceInDays(new Date(), new Date(verification.timestamp)) >= 7) {
                    localForage.removeItem('ageVerification');
                } else if (verification.over21) {
                    return setVisible(false);
                }
            }

            return setVisible(true);
        });
    }, []);

    const handleClick = (over21: boolean) => () => {
        if (over21) {
            localForage.setItem<AgeVerificationData>('ageVerification', { timestamp: Date.now(), over21: true });
            setVisible(false);
        } else {
            localForage.setItem<AgeVerificationData>('ageVerification', { timestamp: Date.now(), over21: false });
            window.location.href = 'https://www.google.com';
        }
    };

    return typeof document !== 'undefined'
        ? createPortal(
              <AnimatePresence>
                  {isVisible && (
                      <motion.div
                          css={s.overlay}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                      >
                          <motion.div
                              css={s.container}
                              initial={{ x: '-50%', y: '-80%' }}
                              animate={{ x: '-50%', y: '-50%' }}
                              exit={{ x: '-50%', y: '-20%' }}
                          >
                              <div css={s.logo}>
                                  <Logo />
                              </div>
                              <h2 css={s.title}>Please Verify Your Age</h2>
                              <p css={s.message}>
                                  You must be 21 or older to enter by law. Any unlawful entry is strictly prohibited.
                                  Please verify your age before entering.
                              </p>
                              <div css={s.buttons}>
                                  <Button onClick={handleClick(true)}>I am 21 or older</Button>
                                  <Button onClick={handleClick(false)} color="#ad2c2c">
                                      I am under 21
                                  </Button>
                              </div>
                          </motion.div>
                      </motion.div>
                  )}
              </AnimatePresence>,
              document.body
          )
        : null;
};

export default AgeVerification;
