import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { InstagramQuery } from '@lib/types';

import * as s from './styles';

const Instagram = () => {
    const data = useStaticQuery<InstagramQuery>(graphql`
        {
            allInstagramPhoto(limit: 8, sort: { fields: [timestamp], order: DESC }) {
                nodes {
                    id
                    permalink
                    localImage {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 200, height: 200, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `);

    return (
        <div css={s.wrapper}>
            <div css={s.container}>
                <h3 css={s.title}>Follow Us on Instagram</h3>
            </div>
            <ul css={s.list}>
                {data.allInstagramPhoto.nodes.map(({ id, permalink, localImage }) => (
                    <li css={s.item} key={id}>
                        <OutboundLink css={s.link} href={permalink}>
                            <GatsbyImage
                                image={localImage.childImageSharp.gatsbyImageData}
                                alt="410 Smoke Shop Instagram photo"
                            />
                        </OutboundLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Instagram;
