import React from 'react';
import { Global } from '@emotion/react';

import Transition from '@components/Transition';
import AgeVerification from '@components/AgeVerification';
// import GlobalAlert from '@components/GlobalAlert';
import { CartProvider } from '@hooks/useCart';

import * as s from './styles';

type Props = {
    location: Location;
};

const Layout: React.FC<Props> = ({ children, location }) => (
    // gatsby-plugin-offline issue with gatsby-plugin-layout
    // https://github.com/gatsbyjs/gatsby/issues/11738
    // if (location.pathname === '/offline-plugin-app-shell-fallback') return null;

    <>
        {/* <GlobalAlert> */}
        <CartProvider>
            <Global styles={s.global} />
            <Transition location={location}>{children}</Transition>
        </CartProvider>
        {/* </GlobalAlert> */}
        <AgeVerification />
    </>
);

export default Layout;
