import { css } from '@emotion/react';

export const overlay = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
`;

export const container = css`
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 2rem;
    background: white;
    border-radius: 0.3rem;
    background: #2d2d2d;
    color: white;
    border: 2px solid #ffb300;
    width: 100%;
    max-width: 26rem;
`;

export const logo = css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const title = css`
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #ffb300;
    font-size: 1.2rem;
    text-transform: uppercase;
`;

export const message = css`
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: 1.1rem;
`;

export const buttons = css`
    & > button:first-of-type {
        margin-bottom: 1rem;
    }

    @media (min-width: 25rem) {
        display: flex;

        & > button {
            flex: 1 1 50%;

            &:first-of-type {
                margin: 0 1rem 0 0;
            }
        }
    }
`;
