import { css, keyframes } from '@emotion/react';

export const button = ({ size }: { size: 'sm' | 'md' | 'lg' }) => css`
    font-size: ${{ sm: 0.9, md: 1, lg: 1.2 }[size]}rem;
    display: block;
    width: 100%;
    color: white;
    border: none;
    border-radius: 0.3em;
    overflow: hidden;
    font-weight: 700;
    white-space: nowrap;
    position: relative;

    &:hover {
        cursor: pointer;

        div {
            transform: scale(1.1);
        }
    }

    &:focus {
        outline: none;
    }

    & > div {
        padding: 0.8em 1.4em;
    }

    & > :not(:first-of-type) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const spin = keyframes`
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const spinner = css`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: '';
        box-sizing: border-box;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        border: 4px solid rgba(255, 255, 255, 0.4);
        border-top-color: rgba(255, 255, 255, 1);
        border-radius: 1rem;
        animation: ${spin} 0.7s linear infinite;
    }
`;
