import { css } from '@emotion/react';

export const footer = css`
    background: #2d2d2d;
    color: white;
    padding: 4rem 0 2rem;
    position: relative;
`;

export const top = css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #ffb300;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.1rem;
    z-index: 2;
    width: 2.5rem;

    @media (min-width: 53.75rem) {
        top: 2rem;
        right: 2rem;
    }
`;

export const blocks = css`
    padding: 0 2.5rem;

    @media (min-width: 31.25rem) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
`;

export const block = css`
    position: relative;
    z-index: 1;
    margin: 0 2rem 3rem;
`;

export const blockHeader = css`
    font-size: 1.4rem;
    font-weight: 600;
    white-space: nowrap;
`;

export const addressBlock = css`
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    address {
        font-style: normal;
        margin-bottom: 1rem;
    }
`;

export const tel = css`
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const hours = css`
    margin-top: 1rem;
`;

export const icons = css`
    @media (min-width: 31.25rem) {
        text-align: center;
    }
`;

export const icon = css`
    display: inline-block;
    max-width: 2rem;
    margin-right: 0.5rem;
    color: white;
    transform: rotate(0deg);
    transition: all 0.3s;

    &:hover {
        color: #ffb300;
        transform: rotate(15deg);
    }

    svg {
        width: 100%;
    }
`;

export const list = css`
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 0;
`;

export const item = css`
    display: inline-block;
    margin: 0 1rem 0.5rem;
`;

export const link = css`
    color: white;
    text-transform: uppercase;
`;

export const policies = css`
    margin-bottom: 2rem;
`;

export const policyLink = css`
    color: white;
    font-size: 0.9rem;
`;

export const copywrite = css`
    text-align: center;
`;

export const logo = css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1rem;
    opacity: 0.3;
    max-width: 100%;

    & > div {
        max-height: 100%;
        max-width: 100%;
    }
`;
