export default function lazyLoadScript<T>(src: string, getLibrary: () => T) {
    let value: Promise<T>;

    const getScript = () => {
        if (!value) {
            value = new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = src;
                script.type = 'text/javascript';
                script.charset = 'utf-8';
                script.addEventListener('load', () => resolve(getLibrary()));
                document.body.appendChild(script);
            });
        }

        return value;
    };

    return getScript;
}
