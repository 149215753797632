import fetch from 'unfetch';
import { IGatsbyImageData } from 'gatsby-plugin-image';

const API_SEARCH_DEV = '/db.json';

interface DBItemGroup {
    slug: string;
    name: string;
    unifiedItems: Array<{
        rootCloverItem: {
            code: string;
            price: number;
            categories: Array<{
                name: string;
                markdownFile: {
                    childMarkdownRemark: {
                        fields: {
                            slug: string;
                        };
                        frontmatter: {
                            title: string;
                        };
                    };
                };
            }>;
            markdownFile: {
                childMarkdownRemark: {
                    html: string;
                };
            };
            imageFiles: Array<{
                childImageSharp: {
                    gatsbyImageData: IGatsbyImageData;
                };
            }>;
        };
    }>;
}

export default function search(text: string) {
    return process.env.NODE_ENV !== 'development'
        ? fetch(process.env.API_SEARCH, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ search: text }),
          })
              .then(
                  (response) =>
                      response.json() as Promise<
                          Array<{
                              item: DBItemGroup;
                          }>
                      >
              )
              .catch(() => [])
              .then((results) => results.slice(0, 9).map((result) => result.item))
        : new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
              fetch(API_SEARCH_DEV, {
                  method: 'GET',
              })
                  .then((response) => response.json() as Promise<Array<DBItemGroup>>)
                  .then((results) =>
                      results.filter((result) => result.name.toLowerCase().includes(text.toLowerCase())).slice(0, 9)
                  )
          );
}
