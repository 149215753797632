import { css } from '@emotion/react';

export const wrapper = css`
    position: relative;
    /* prevents extra scrollbar jank when switching to a longer page */
    overflow: hidden;

    & > :nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
`;

export const transitionWrapper = (isHome) => css`
    &::before {
        content: '';
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding-top: ${isHome ? '30%' : '64px'};
        min-height: ${isHome ? '22rem' : '64px'};
    }
`;
