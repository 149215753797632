import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Logo = () => (
    <StaticImage
        src="../../content/images/410-logo.png"
        alt="410 Smoke Shop logo"
        layout="fixed"
        width={120}
        placeholder="blurred"
    />
);

export default Logo;
