export const cleanPhone = (phone: string) => phone.replace(/\D/g, '');

export const formatPhone = (phone: string) =>
    cleanPhone(phone).replace(/(\d{3})(\d{3})(\d{4})/, (_, p1, p2, p3) => `(${p1}) ${p2} - ${p3}`);

export const formatNumberToTimeString = (number: number) => {
    const minutes = number % 100;
    const hours = (number - minutes) / 100;

    return hours > 12
        ? `${hours - 12}:${minutes.toString().padStart(2, '0')} pm`
        : `${hours}:${minutes.toString().padStart(2, '0')} am`;
};
