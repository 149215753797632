import React, { createContext, useContext, useState, useEffect } from 'react';
import { differenceInDays } from 'date-fns';
import localForage from 'localforage';

import { Item } from '@lib/types';

const SHIPPING_COST = 1000;

interface CartData {
    timestamp: number;
    items: Item[];
}

interface PromotionData {
    code: string;
    type: 'amount' | 'percentage';
    value: number;
    threshold: number;
}

const createDiscounter = (totalPrice: number, promotions: PromotionData[]) => {
    const discounts = promotions.reduce(
        (acc, promo) => {
            if (totalPrice >= promo.threshold) {
                acc[promo.type] += promo.value;
                acc.activePromotions.push(promo);
            }

            return acc;
        },
        {
            activePromotions: [] as PromotionData[],
            amount: 0,
            percentage: 0,
        }
    );

    const totalDiscount = Math.min(
        totalPrice * 10000000, // Can't be more than the total cart value
        totalPrice * discounts.percentage + discounts.amount * 10000000
    );

    return {
        activePromotions: discounts.activePromotions,
        discount: (value: number, base: number = 1) =>
            totalPrice === 0
                ? 0
                : Math.round(((totalPrice * 10000000 - totalDiscount) * value) / (totalPrice * 10000000 * base)),
    };
};

const CartContext = createContext<{
    add: (item: Omit<Item, 'quantity'>, quantity?: number) => void;
    remove: (item: Omit<Item, 'quantity'>, quantity?: number) => void;
    clear: () => void;
    setDelivery: (value: boolean) => void;
    setInState: (value: boolean) => void;
    addPromotion: (value: PromotionData) => void;
    isDelivery: boolean;
    isInState: boolean;
    promotions: PromotionData[];
    activePromotions: PromotionData[];
    items: Item[];
    quantity: number;
    totalPrice: number;
    totalTax: number;
    totalShipping: number;
    totalDiscount: number;
    total: number;
}>({
    add: () => {},
    remove: () => {},
    clear: () => {},
    setDelivery: () => {},
    setInState: () => {},
    addPromotion: () => {},
    isDelivery: false,
    isInState: true,
    promotions: [],
    activePromotions: [],
    items: [],
    quantity: 0,
    totalPrice: 0,
    totalTax: 0,
    totalShipping: 0,
    totalDiscount: 0,
    total: 0,
});

export default function useCart() {
    return useContext(CartContext);
}

export const CartProvider: React.FC = ({ children }) => {
    const [items, setItems] = useState<Item[]>([]);
    const [isDelivery, setDelivery] = useState(false);
    const [isInState, setInState] = useState(true);
    const [promotions, setPromotions] = useState<PromotionData[]>([]);

    useEffect(() => {
        localForage.getItem<CartData>('cart').then((cart) => {
            if (cart) {
                if (differenceInDays(new Date(), new Date(cart.timestamp)) >= 1) {
                    localForage.removeItem('cart');
                } else {
                    setItems(cart.items);
                }
            }
        });
    }, []);

    const addPromotion = (promotion: PromotionData) => {
        setPromotions([...promotions, promotion]);
    };

    const add = async (item: Omit<Item, 'quantity'>, quantity: number = 1) => {
        const existingItem = items.find((i) => i.code === item.code);

        let newItems;
        if (existingItem) {
            const index = items.indexOf(existingItem);
            newItems = [
                ...items.slice(0, index),
                { ...existingItem, quantity: existingItem.quantity + quantity },
                ...items.slice(index + 1),
            ];
        } else {
            newItems = [...items, { ...item, quantity }];
        }

        setItems(newItems);
        localForage.setItem<CartData>('cart', { timestamp: Date.now(), items: newItems });
    };

    const remove = (item: Omit<Item, 'quantity'>, quantity: number = 1) => {
        const existingItem = items.find((i) => i.code === item.code);

        if (existingItem) {
            const index = items.indexOf(existingItem);

            const newItems =
                existingItem.quantity - quantity > 0
                    ? [
                          ...items.slice(0, index),
                          { ...existingItem, quantity: existingItem.quantity - quantity },
                          ...items.slice(index + 1),
                      ]
                    : [...items.slice(0, index), ...items.slice(index + 1)];

            setItems(newItems);
            localForage.setItem<CartData>('cart', { timestamp: Date.now(), items: newItems });
        }
    };

    const clear = () => {
        setItems([]);
        setPromotions([]);
        setInState(true);
        setDelivery(false);
        localForage.removeItem('cart');
    };

    const { quantity, intPrice, intTax } = items.reduce(
        (acc, item) => {
            const itemTotal = item.price * item.quantity;
            const tPrice = acc.intPrice + itemTotal;
            const tTaxRate = item.taxRates.elements.reduce((acc2, { rate }) => acc2 + rate, 0);
            const tTax = acc.intTax + itemTotal * tTaxRate;

            return {
                intPrice: tPrice,
                intTax: tTax,
                quantity: acc.quantity + item.quantity,
            };
        },
        { intPrice: 0, intTax: 0, quantity: 0 }
    );

    const { activePromotions, discount } = createDiscounter(intPrice, promotions);

    const totalPrice = discount(intPrice);
    const totalTax = discount(intTax, 10000000);
    const totalShipping = isDelivery ? 0 : SHIPPING_COST;
    const totalDiscount = intPrice - totalPrice;
    const total = totalPrice + totalTax + totalShipping;

    return (
        <CartContext.Provider
            value={{
                items,
                add,
                remove,
                clear,
                quantity,
                total,
                totalPrice,
                totalTax,
                totalShipping,
                totalDiscount,
                isDelivery,
                setDelivery,
                isInState,
                setInState,
                addPromotion,
                promotions,
                activePromotions,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
