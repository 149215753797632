import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import * as s from './styles';

type Props = {
    onClick?: React.MouseEventHandler;
    id?: string | number;
    type?: 'button' | 'submit';
    size?: 'sm' | 'md' | 'lg';
    color?: string;
    form?: string;
};

const Button: React.FC<Props> = ({
    children,
    id = typeof children === 'string' ? children : '__key__',
    onClick,
    form,
    type = 'button',
    size = 'md',
    color = '#ffb300',
    ...rest
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.style.height = `${buttonRef.current.offsetHeight}px`;
        }
    }, []);

    return (
        <motion.button
            css={s.button({ size })}
            ref={buttonRef}
            type={type}
            initial={false}
            animate={{ background: color }}
            onClick={onClick}
            form={form}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            <AnimatePresence initial={false}>
                <motion.div
                    key={id}
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -30, opacity: 0 }}
                    whileHover={{ scale: 1.1 }}
                >
                    {children === '__LOADING__' ? <div css={s.spinner} /> : children}
                </motion.div>
            </AnimatePresence>
        </motion.button>
    );
};

export default Button;
