import { css } from '@emotion/react';

const MIN_HERO_HEIGHT = 352;

export const header = css`
    position: relative;
    z-index: 5;
`;

export const navWrapper = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
`;

export const navGradient = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`;

export const hero = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 30%;
    min-height: ${MIN_HERO_HEIGHT / 16}rem;
    background: #2d2d2d;
    pointer-events: none;
`;

export const heroImageWrapper = css`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(circle, white 80%, transparent 0),
            radial-gradient(circle, white 70%, transparent 0), radial-gradient(circle, white 60%, transparent 0),
            radial-gradient(circle, white 50%, transparent 0), radial-gradient(circle, white 40%, transparent 0),
            radial-gradient(circle, white 30%, transparent 0), radial-gradient(circle, white 20%, transparent 0);
        background-size: 0.5rem 0.5rem;
        background-repeat: repeat-x;
        background-position: 0 0, 0 0.5rem, 0 1rem, 0 1.5rem, 0 2rem, 0 2.5rem, 0 3rem;
        transform: rotate(180deg);
    }
`;

export const heroImage = css`
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    & :first-of-type {
        min-height: ${MIN_HERO_HEIGHT / 16}rem;
    }
`;

export const heroTitle = css`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2rem;
    line-height: 1;

    @media (min-width: 75rem) {
        font-size: 2.5rem;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        background: #ffb300;
        top: 10%;
        left: -10%;
        width: 120%;
        height: 80%;
        z-index: -1;
        transform: rotate(10deg);
        opacity: 0.8;
    }
`;
