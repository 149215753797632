type NavigationConfig = Array<{
    name: string;
    path: string;
    partial?: boolean;
}>;

const config: NavigationConfig = [
    {
        name: 'Home',
        path: '/',
    },
    // {
    //     name: 'Shop',
    //     path: '/products',
    //     partial: true,
    // },
    {
        name: 'Locations',
        path: '/locations',
    },
    {
        name: 'About Us',
        path: '/about',
    },
    {
        name: 'Contact Us',
        path: '/contact',
    },
];

export default config;
