import { css } from '@emotion/react';

const MOBILE_MENU_BREAKPOINT = 1000;

export const nav = css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 77rem;
    padding: 0 1rem;
    margin: auto;
    position: relative;
`;

export const logo = css`
    margin-top: 0.5rem;
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);

    &:hover {
        transform: rotate(5deg);
    }
`;

export const menuBtn = css`
    background: none;
    border: none;
    display: flex;
    font-weight: 700;
    font-size: 0.9rem;
    color: white;
    text-transform: uppercase;
    padding: 0.4rem;
    margin: 1.1rem 0 0 auto;
    font-family: Rubik, Helvetica, Arial, sans-serif;

    svg {
        display: block;
        color: #ffb300;
        width: 1rem;
        margin-right: 0.5rem;
    }

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        display: none;
    }
`;

export const overlay = css`
    background: none;
    border: none;
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
`;

export const listWrapper = css`
    flex: 1 1 auto;

    @media (max-width: ${MOBILE_MENU_BREAKPOINT - 1}px) {
        position: fixed;
        top: 0;
        left: 100%;
        height: 100%;
        z-index: 10;
        background: #ad2c2c;
        padding: 4rem 1rem 1rem 1rem;

        &::before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: calc(100% + 5rem);
            height: 100%;
            z-index: -1;
        }

        &::before {
            left: 100%;
            width: 5rem;
            background: #ad2c2c;
            z-index: -2;
        }

        &::after {
            background: linear-gradient(#ad2c2c, transparent 80%),
                url('https://www.colourbox.com/preview/8289755-black-and-white-seamless-texture-tribal.jpg');
            background-size: 24rem;
            opacity: 0.05;
            z-index: -2;
        }
    }
`;

export const close = css`
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    width: 2rem;
    color: white;
    background: none;
    border: none;
    padding: 0.5rem;
    z-index: 5;

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        display: none;
    }
`;

export const list = css`
    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        margin: 1rem 0 0 2rem;
        list-style: none;
    }
`;

export const item = css`
    display: block;

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        display: inline-block;
    }
`;

export const link = css`
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;

    @media (max-width: ${MOBILE_MENU_BREAKPOINT - 1}px) {
        font-size: 1.1rem;
        display: block;
        padding: 1rem 3rem;
        transition: all 0.3s;
        transform: rotate(0deg);
        border-radius: 0.5rem;
        margin: 0 1rem;
        white-space: nowrap;

        &.active {
            background: #ffb300;
        }
    }

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        font-size: 0.9rem;
        padding: 0.4rem;
        margin: 0 0.4rem;
        transition: 0.3s color;
        display: inline-block;
        transition: all 0.3s;
        transform: rotate(0deg);

        &:hover {
            color: #ffb300;
            transform: rotate(5deg);
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0.2rem;
            left: 0;
            width: 100%;
            transform: scaleX(0);
            display: block;
            height: 3px;
            background: #ffb300;
            transition: all 0.3s;
        }

        &.active::after {
            transform: scaleX(1);
        }
    }
`;

export const tel = css`
    display: none;
    flex: 1 1 auto;
    justify-self: end;
    color: white;
    line-height: 1;
    padding-top: 0.7rem;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 500;

    @media (min-width: 33rem) {
        display: block;
    }

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        flex: 0 0 auto;
        text-align: right;
    }

    a {
        color: #ffb300;
        font-weight: 700;
        text-decoration: none;
        font-size: 1.4rem;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const menuTel = css`
    ${tel}
    display: block;
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;

    @media (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
        display: none;
    }
`;

export const cartWrapper = css`
    position: relative;
    margin: 1rem 1rem 0 2rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -1rem;
        top: 0;
        width: 2px;
        height: 100%;
        background: #ffb300;
    }
`;
