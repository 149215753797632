/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({ routerProps }) => {
    const { state } = routerProps.location;

    if (state && state.shouldUpdateScroll === false) {
        // This is manually set with a Gatsby navigate() call
        delete state.shouldUpdateScroll;

        return false;
    }

    return true;
};
