import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Navigation from '@components/Navigation';

import * as s from './styles';

const MIN_NAV_HEIGHT = 64;
// const HERO_RATIO = 0.3;
// const OFFSET = MIN_HERO_HEIGHT - MIN_NAV_HEIGHT;

const spring = {
    type: 'spring',
    damping: 30,
    stiffness: 325,
};

type Props = {
    isHome: boolean;
};

const Header: React.FC<Props> = ({ isHome }) => (
    <header css={s.header}>
        <div css={s.navWrapper}>
            <Navigation />
            <motion.div css={s.navGradient} animate={{ opacity: isHome ? 1 : 0 }} transition={spring} initial={false} />
        </div>
        <motion.div
            css={s.hero}
            animate={{ y: isHome ? 0 : `calc(-100% + ${MIN_NAV_HEIGHT}px)` }}
            transition={spring}
            initial={false}
        >
            <motion.h2
                css={s.heroTitle}
                animate={{
                    opacity: isHome ? 1 : 0,
                    rotate: '-10deg',
                    x: '-50%',
                    y: isHome ? '-50%' : `150%`,
                }}
                transition={spring}
                initial={false}
            >
                Feelin&apos;
                <br />
                Goooood
            </motion.h2>
            <motion.div
                css={s.heroImageWrapper}
                animate={{ opacity: isHome ? 1 : 0 }}
                transition={spring}
                initial={false}
            >
                <div css={s.heroImage}>
                    <StaticImage
                        src="../../content/images/girl-smoking.jpg"
                        alt="Girl smoking"
                        layout="constrained"
                        width={2160}
                        placeholder="blurred"
                    />
                </div>
            </motion.div>
        </motion.div>
    </header>
);

Header.propTypes = {
    isHome: PropTypes.bool.isRequired,
};

export default Header;
