type LocationsConfig = Record<
    string,
    | {
          map: string;
          link: string;
          closings: Array<string>;
      }
    | undefined
>;

const config: LocationsConfig = {
    pasadena: {
        map:
            'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJsx4oR6b-t4kRfcXyfqkFUPU&key=AIzaSyCfp_WVH_z8YNwA01iFwQFdVucyGZsN6R4',
        link:
            'https://www.google.com/maps/place/410+Smoke+Shop/@39.128038,-76.54489,15z/data=!4m5!3m4!1s0x0:0xf55005a97ef2c57d!8m2!3d39.128038!4d-76.54489',
        closings: ['11/26/2020', '12/25/2020'],
    },
    laurel: {
        map:
            'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJPdXx4UXdt4kRVc1mcyXGVjs&key=AIzaSyCfp_WVH_z8YNwA01iFwQFdVucyGZsN6R4',
        link:
            'https://www.google.com/maps/place/410+Smoke+Shop/@39.103128,-76.8453858,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x3b56c6257366cd55!8m2!3d39.103128!4d-76.8431971',
        closings: ['11/26/2020', '12/25/2020'],
    },
};

export default config;
