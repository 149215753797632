// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-index-tsx": () => import("./../../../src/pages/locations/index.tsx" /* webpackChunkName: "component---src-pages-locations-index-tsx" */),
  "component---src-pages-policy-privacy-index-tsx": () => import("./../../../src/pages/policy/privacy/index.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-index-tsx" */),
  "component---src-pages-policy-return-index-tsx": () => import("./../../../src/pages/policy/return/index.tsx" /* webpackChunkName: "component---src-pages-policy-return-index-tsx" */),
  "component---src-pages-policy-terms-index-tsx": () => import("./../../../src/pages/policy/terms/index.tsx" /* webpackChunkName: "component---src-pages-policy-terms-index-tsx" */),
  "component---src-templates-product-group-index-tsx": () => import("./../../../src/templates/ProductGroup/index.tsx" /* webpackChunkName: "component---src-templates-product-group-index-tsx" */),
  "component---src-templates-products-index-tsx": () => import("./../../../src/templates/Products/index.tsx" /* webpackChunkName: "component---src-templates-products-index-tsx" */)
}

