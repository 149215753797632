import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Header from '@components/Header';
import Footer from '@components/Footer';
import Instagram from '@components/Instagram';

import * as s from './styles';

type Props = {
    location: Location;
};

const Transition: React.FC<Props> = ({ children, location }) => (
    <>
        <Header isHome={location.pathname === '/'} />
        <div css={s.wrapper}>
            <AnimatePresence initial={false}>
                <motion.div
                    key={location.pathname}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    css={s.transitionWrapper(location.pathname === '/')}
                >
                    {children}
                    <Instagram />
                    <Footer />
                </motion.div>
            </AnimatePresence>
        </div>
    </>
);

export default Transition;
