import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { formatPhone, cleanPhone } from '@lib';
import { MerchantPhoneQuery } from '@lib/types';
import navigation from '@config/navigation.config';
import Icon from '@components/Icon';
// import Cart from '@components/CartMenu';

import * as s from './styles';

const Navigation = () => {
    const [isOpen, setOpen] = React.useState(false);
    const { cloverMerchant } = useStaticQuery<MerchantPhoneQuery>(graphql`
        {
            cloverMerchant(address: { city: { regex: "/pasadena/i" } }) {
                address {
                    phoneNumber
                }
            }
        }
    `);

    return (
        <nav css={s.nav}>
            <Link to="/">
                <StaticImage
                    css={s.logo}
                    src="../../content/images/410-logo.png"
                    alt="410 Smoke shop logo"
                    layout="fixed"
                    width={100}
                    placeholder="blurred"
                />
            </Link>
            <motion.div css={s.listWrapper} initial={false} animate={{ x: isOpen ? '-100%' : 0 }}>
                <button css={s.close} type="button" onClick={() => setOpen(false)}>
                    <Icon name="times" />
                </button>
                <ul css={s.list}>
                    {navigation.map(({ name, path, partial }) => (
                        <li css={s.item} key={name}>
                            <Link
                                css={s.link}
                                to={path}
                                activeClassName="active"
                                partiallyActive={partial}
                                onClick={() => setOpen(false)}
                            >
                                {name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div css={s.menuTel}>
                    Give us a call <br />
                    <OutboundLink href={`tel:+1${cleanPhone(cloverMerchant.address.phoneNumber)}`}>
                        {formatPhone(cloverMerchant.address.phoneNumber)}
                    </OutboundLink>
                </div>
            </motion.div>
            <div css={s.tel}>
                Give us a call <br />
                <OutboundLink href={`tel:+1${cleanPhone(cloverMerchant.address.phoneNumber)}`}>
                    {formatPhone(cloverMerchant.address.phoneNumber)}
                </OutboundLink>
            </div>
            <button css={s.menuBtn} type="button" onClick={() => setOpen(!isOpen)}>
                <Icon name="bars" />
                Menu
            </button>
            <div css={s.cartWrapper}>{/* <Cart /> */}</div>
            <motion.button
                css={s.overlay}
                initial={false}
                animate={{ opacity: isOpen ? 1 : 0 }}
                style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
                type="button"
                aria-label="close"
                onClick={() => setOpen(false)}
            />
        </nav>
    );
};

export default Navigation;
