import { css } from '@emotion/react';

export const wrapper = css``;

export const container = css`
    max-width: 77rem;
    padding: 0 1rem;
    margin: 2rem auto 0;
`;

export const title = css`
    color: #ad2c2c;
    margin-bottom: -1.8vw;
    font-size: 6.5vw;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;

    @media (min-width: 27rem) {
        font-size: 1.8rem;
        margin-bottom: -0.5rem;
    }
`;

export const list = css`
    position: relative;
    padding: 1.5rem 0.75rem;
    display: flex;
    list-style: none;
    z-index: 1;
    flex-wrap: wrap;

    @media (min-width: 53.75rem) {
        flex-wrap: nowrap;
    }

    &::before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::before {
        background: #ad2c2c;
    }

    &::after {
        background: url('https://www.colourbox.com/preview/8289755-black-and-white-seamless-texture-tribal.jpg');
        background-size: 24rem;
        opacity: 0.05;
    }
`;

export const item = css`
    position: relative;
    flex: 1 1 40%;
    z-index: 2;
    margin: 0.5rem;

    @media (min-width: 32rem) {
        flex: 1 1 20%;
    }

    @media (min-width: 53.75rem) {
        margin: 0 0.25rem;
    }

    @media (min-width: 75rem) {
        margin: 0 0.75rem;

        &:not(:last-of-type)::after {
            content: '';
            position: absolute;
            width: 1rem;
            height: 7rem;
            top: 50%;
            right: -1.25rem;
            transform: translateY(-50%);
            background-image: radial-gradient(circle, #ffb300 20%, transparent 0),
                radial-gradient(circle, #ffb300 30%, transparent 0), radial-gradient(circle, #ffb300 40%, transparent 0),
                radial-gradient(circle, #ffb300 50%, transparent 0), radial-gradient(circle, #ffb300 40%, transparent 0),
                radial-gradient(circle, #ffb300 30%, transparent 0), radial-gradient(circle, #ffb300 20%, transparent 0);
            background-size: 1rem 1rem;
            background-repeat: no-repeat;
            background-position: 0 0, 0 1rem, 0 2rem, 0 3rem, 0 4rem, 0 5rem, 0 6rem;
        }
    }
`;

export const link = css`
    display: block;
    border-radius: 0.5rem;
    overflow: hidden;
    line-height: 0;
`;
