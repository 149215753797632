import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { StaticImage } from 'gatsby-plugin-image';

import { MerchantsQuery } from '@lib/types';
import { formatPhone, cleanPhone, formatNumberToTimeString } from '@lib';
import Icon from '@components/Icon';
import navigation from '@config/navigation.config';
import locationsMeta from '@config/locations.config';

import * as s from './styles';

const Footer = () => {
    const { allCloverMerchant } = useStaticQuery<MerchantsQuery>(graphql`
        query MerchantsFooter {
            allCloverMerchant {
                nodes {
                    address {
                        address1
                        city
                        phoneNumber
                        state
                        zip
                    }
                    openingHours {
                        elements {
                            friday {
                                elements {
                                    end
                                    start
                                }
                            }
                            monday {
                                elements {
                                    end
                                    start
                                }
                            }
                            saturday {
                                elements {
                                    end
                                    start
                                }
                            }
                            sunday {
                                elements {
                                    end
                                    start
                                }
                            }
                            thursday {
                                elements {
                                    end
                                    start
                                }
                            }
                            tuesday {
                                elements {
                                    start
                                    end
                                }
                            }
                            wednesday {
                                elements {
                                    end
                                    start
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <footer css={s.footer}>
            <button css={s.top} type="button" onClick={() => window.scrollTo(0, 0)}>
                <Icon name="caret-square-up" />
                Top
            </button>
            <div css={s.blocks}>
                {allCloverMerchant.nodes.map(({ address, openingHours }) => {
                    const { start, end } = openingHours.elements[0].monday.elements[0];

                    return (
                        <div css={s.block} key={address.city}>
                            <h5 css={s.blockHeader}>{address.city}</h5>
                            <OutboundLink css={s.addressBlock} href={locationsMeta[address.city.toLowerCase()]?.link}>
                                <address>
                                    {address.address1}
                                    <br />
                                    {address.city} {address.state} {address.zip}
                                </address>
                            </OutboundLink>
                            <OutboundLink css={s.tel} href={`tel:+1${cleanPhone(address.phoneNumber)}`}>
                                {formatPhone(address.phoneNumber)}
                            </OutboundLink>
                            <p css={s.hours}>
                                Sun - Sat: {formatNumberToTimeString(start)} - {formatNumberToTimeString(end)}
                            </p>
                        </div>
                    );
                })}
                <div css={s.block}>
                    <h5 css={s.blockHeader}>Social Media</h5>
                    <div css={s.icons}>
                        <OutboundLink css={s.icon} href="https://www.facebook.com/410smokeshop/">
                            <Icon name="facebook-square" />
                        </OutboundLink>
                        <OutboundLink css={s.icon} href="https://www.instagram.com/410smokeshop/">
                            <Icon name="instagram" />
                        </OutboundLink>
                    </div>
                </div>
            </div>
            <ul css={s.list}>
                {navigation.map(({ name, path }) => (
                    <li css={s.item} key={name}>
                        <Link css={s.link} to={path}>
                            {name}
                        </Link>
                    </li>
                ))}
            </ul>
            <ul css={[s.list, s.policies]}>
                <li css={s.item}>
                    <Link css={s.policyLink} to="/policy/privacy">
                        Privacy Policy
                    </Link>
                </li>
                <li css={s.item}>
                    <Link css={s.policyLink} to="/policy/terms">
                        Terms &amp; Conditions
                    </Link>
                </li>
                <li css={s.item}>
                    <Link css={s.policyLink} to="/policy/return">
                        Return &amp; Refund Policy
                    </Link>
                </li>
            </ul>
            <p css={s.copywrite}>&copy; {new Date().getFullYear()} 410 Smoke Shop, Inc.</p>
            <div css={s.logo}>
                <StaticImage
                    src="../../content/images/410-logo-bw.png"
                    alt="410 Smoke Shop logo"
                    layout="fixed"
                    height={400}
                    placeholder="blurred"
                    objectFit="contain"
                />
            </div>
        </footer>
    );
};

export default Footer;
